import { CommonButton } from "@Components/common/CommonButton";
import { useHeroContext } from "@Context/Hero.context";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import CollegeOrGradeSvgIcon from "public/svgs/icons/global-icons/CollegeSvgIcon";
import CountrySvgIcon from "public/svgs/icons/global-icons/CountrySvgIcon";
import LightArrowRightSvgIcon from "public/svgs/icons/global-icons/LightArrowRightSvgIcon";
import StudyStageSvgIcon from "public/svgs/icons/global-icons/StudyStageSvgIcon";
import {
  ArrowDirectionWrapper,
  CTAMobileContainer,
  CTAMobileHolder,
  CTAMobileLi,
  CTAMobileLiContent,
} from "../../HeroArea.styled";

interface Props {}

export default function CTAMobile({}: Props) {
  const router = useRouter();
  const { t } = useTranslation("common");
  const { setIsCTAMobileLayer, currentSelectedStudyStage, CTAURL } = useHeroContext();
  return (
    <CTAMobileHolder>
      <CTAMobileContainer>
        <CTAMobileLi variant="default" onClick={() => setIsCTAMobileLayer(true)}>
          <CTAMobileLiContent>
            <CountrySvgIcon margin="0 8px" />
            {t("country")}
          </CTAMobileLiContent>
          <ArrowDirectionWrapper>
            <LightArrowRightSvgIcon color="#1a1a1a" width="9" height="14" />
          </ArrowDirectionWrapper>
        </CTAMobileLi>
        <CTAMobileLi variant="default" onClick={() => setIsCTAMobileLayer(true)}>
          <CTAMobileLiContent>
            <StudyStageSvgIcon margin="0 8px" />
            {t("study-stage")}
          </CTAMobileLiContent>
          <ArrowDirectionWrapper>
            <LightArrowRightSvgIcon color="#1a1a1a" width="9" height="14" />
          </ArrowDirectionWrapper>
        </CTAMobileLi>
        <CTAMobileLi variant="default" onClick={() => setIsCTAMobileLayer(true)}>
          <CTAMobileLiContent>
            <CollegeOrGradeSvgIcon margin="0 8px" />
            {t("college-or-grade")}
          </CTAMobileLiContent>
          <ArrowDirectionWrapper>
            <LightArrowRightSvgIcon color="#1a1a1a" width="9" height="14" />
          </ArrowDirectionWrapper>
        </CTAMobileLi>
        <CTAMobileLi variant="empty-bg">
          <CommonButton
            onClick={() => (currentSelectedStudyStage.name && CTAURL ? router.push(CTAURL) : setIsCTAMobileLayer(true))}
            variant="default"
          >
            {t("search")}
          </CommonButton>
        </CTAMobileLi>
      </CTAMobileContainer>
    </CTAMobileHolder>
  );
}
