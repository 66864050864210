import { BREADCRUMB, COLLEGE_OR_GRADE, COUNTRY, STUDY_STAGE } from "@Constants/Breadcrumb";
import { getOnBoardingCategoriesByID } from "@Services/Categories";
import { handleNestedChildren } from "@Utils/Breadcrumb";
import Trans from "next-translate/Trans";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocalStorage } from "react-use";
import { CategoriesType } from "types/categories";
import { HeroCTAContext } from "types/heroCTA";

interface Props {
  children: React.ReactNode;
  isCountriesLoading: Boolean;
  countriesData: CategoriesType;
}

// Category Initial Object
const CategoryInitial = {
  id: 0,
  slug: <Trans i18nKey="common:choose" />,
  name: <Trans i18nKey="common:choose" />,
  short_name: <Trans i18nKey="common:choose" />,
  image: {
    url: "",
  },
  has_children: false,
  type: "",
  children: [],
};

const heroInitState: HeroCTAContext = {
  countriesHandler: CategoryInitial,
  currentSelectedCountry: CategoryInitial,
  setCountriesHandler: () => null,
  setCurrentSelectedCountry: () => null,
  isCountriesLoadingValue: false,
  currentSelectedStudyStage: CategoryInitial,
  setCurrentSelectedStudyStage: () => null,
  currentSelectedCollege: CategoryInitial,
  setCurrentSelectedCollege: () => null,
  isError: false,
  setIsError: () => null,
  isCTAMobileLayer: false,
  setIsCTAMobileLayer: () => null,
  currentSelectedStep: null,
  setCurrentSelectedStep: () => null,
  handleElementClick: () => null,
  errorHandler: () => null,
  isCategoriesLoading: false,
  breadcrumb: [],
  categoriesData: null,
  setBreadcrumb: () => null,
  currentSelectedValue: CategoryInitial,
  setCurrentSelectedValue: () => null,
  CTAURL: "",
};

const HeroContext = React.createContext<HeroCTAContext>(heroInitState);
export const useHeroContext = () => useContext(HeroContext);
HeroContext.displayName = "HeroArea Context";

export default function HeroAreaProvider({ children, countriesData, isCountriesLoading }: Props): ReactElement {
  const [CTAURL, setCTAURL] = useState<string>(null);
  const [countriesHandler, setCountriesHandler] = useState<CategoriesType>(null);
  const [currentSelectedCountry, setCurrentSelectedCountry] = useState<CategoriesType>(CategoryInitial);
  const [isCountriesLoadingValue, setCountriesIsLoading] = useState<Boolean>(false);
  const [currentSelectedStudyStage, setCurrentSelectedStudyStage] = useState<CategoriesType>(CategoryInitial);
  const [currentSelectedCollege, setCurrentSelectedCollege] = useState<CategoriesType>(CategoryInitial);
  const [currentSelectedValue, setCurrentSelectedValue] = useState<CategoriesType>(CategoryInitial);
  const [currentSelectedStep, setCurrentSelectedStep] = useState<string | null>(COUNTRY);
  const [isError, setIsError] = useState<Boolean>(false);
  const [isCTAMobileLayer, setIsCTAMobileLayer] = useState<Boolean>(false);
  const [breadcrumb, setBreadcrumb] = useLocalStorage(BREADCRUMB, []);

  const errorHandler = (item) => item.id !== 0;
  const isEmpty = (item) => item.id == 0;

  // Refetch categories on currentSelectedValue change && not ID=0
  const { data: categoriesData, isLoading: isCategoriesLoading } = useQuery(
    [`categories-${currentSelectedValue.id}`, currentSelectedValue],
    () => handleElementClick({ item: currentSelectedValue, setArrName: breadcrumb, step: currentSelectedValue.step }),
    {
      enabled: isEmpty(currentSelectedValue) ? false : true,
    }
  );

  const handleStatesChanger = ({ item, step, childrens, status }) => {
    // Change States and use it later in CTA Desktop dropdown (Re-handle childrens comes from API to be in one Array)
    if (status === "success") {
      if (step === COUNTRY) {
        setCurrentSelectedCountry({ ...item, children: childrens, type: COUNTRY });
        setCurrentSelectedStudyStage({ ...CategoryInitial, children: childrens });
        setCurrentSelectedCollege(CategoryInitial);
      } else if (step === STUDY_STAGE) {
        setCurrentSelectedStudyStage({ ...item, children: currentSelectedCountry.children });
        setCurrentSelectedCollege({ ...CategoryInitial, children: childrens });
      } else if (step === COLLEGE_OR_GRADE) {
        setCurrentSelectedCollege({ ...item, children: currentSelectedCollege.children });
      }
    } else {
      if (step === STUDY_STAGE) {
        setCurrentSelectedStudyStage({ ...item, children: currentSelectedCountry.children });
        setCurrentSelectedCollege(CategoryInitial);
      } else if (step === COLLEGE_OR_GRADE) {
        setCurrentSelectedCollege({ ...item, children: currentSelectedCollege.children });
      }
    }
  };

  const handleElementClick = async ({ item, setArrName, step }) => {
    return await getOnBoardingCategoriesByID(item.id)
      .then(({ data: { data } }) => {
        // Add current Selected value for (Country/College or grade/Study Stage)
        handleStatesChanger({
          item: item,
          step: step,
          childrens: data.options,
          status: "success",
        });

        // Add Selected Item OBJECT to localstorage
        setBreadcrumb(handleNestedChildren({ items: breadcrumb, childrens: data.options, newObj: item, step }));
        return data;
      })
      .catch((err) => {
        // Add current Selected value for (Country/College or grade/Study Stage)
        handleStatesChanger({
          item: item,
          step: step,
          childrens: [],
          status: "err",
        });

        // Handle Err Items OBJECT in localstorage
        setBreadcrumb(handleNestedChildren({ items: breadcrumb, childrens: [], newObj: item, step }));
        setIsCTAMobileLayer(false);
      });
  };

  useEffect(() => {
    setCountriesHandler(countriesData);
    setCountriesIsLoading(isCountriesLoading);
  }, [countriesData, isCountriesLoading]);

  useEffect(() => {
    if (isCTAMobileLayer) {
      document.querySelector("header").style.display = "none";
    } else {
      document.querySelector("header").style.display = "block";
    }
  }, [isCTAMobileLayer]);

  useEffect(() => {
    if (breadcrumb) {
      // Reset breadcrumb into CTA Desktop dropdown and take only first 3 elements from array because CTA Desktop only for 3 elements
      for (let i = 0; i <= 2; i++) {
        if (breadcrumb[i]?.step === COUNTRY) {
          setCurrentSelectedCountry({ ...breadcrumb[i], children: breadcrumb[i]?.children, type: COUNTRY });
          setCurrentSelectedStudyStage({ ...CategoryInitial, children: breadcrumb[i]?.children, type: STUDY_STAGE });
        } else if (breadcrumb[i]?.step === STUDY_STAGE) {
          // We use children of index = 0 in breadcrumb Array
          setCurrentSelectedStudyStage({ ...breadcrumb[i], children: breadcrumb[i - 1]?.children, type: STUDY_STAGE });
        } else {
          // We use children of index = 1 in breadcrumb Array
          setCurrentSelectedCollege({
            ...breadcrumb[i],
            children: breadcrumb[i - 1]?.children,
            type: COLLEGE_OR_GRADE,
          });
        }
        if (breadcrumb.length > 0) {
          setCTAURL(
            `/home/${breadcrumb[0].slug}${breadcrumb[1] ? `/${breadcrumb[1].slug}` : ""}${
              breadcrumb[2] ? `/${breadcrumb[2].slug}` : ""
            }`
          );
        }
      }
    }
  }, [breadcrumb]);

  useEffect(() => {
    // Update current Step based on step change
    setIsError(false);
    if (isEmpty(currentSelectedCountry)) {
      setCurrentSelectedStep(COUNTRY);
    } else if (isEmpty(currentSelectedStudyStage)) {
      setCurrentSelectedStep(STUDY_STAGE);
    } else {
      setCurrentSelectedStep(COLLEGE_OR_GRADE);
    }
  }, [currentSelectedCountry, currentSelectedStudyStage, currentSelectedCollege]);

  return (
    <HeroContext.Provider
      value={{
        countriesHandler,
        currentSelectedCountry,
        setCurrentSelectedCountry,
        isCountriesLoadingValue,
        setCountriesHandler,
        currentSelectedCollege,
        setCurrentSelectedCollege,
        currentSelectedStudyStage,
        currentSelectedStep,
        setCurrentSelectedStudyStage,
        isError,
        CTAURL,
        breadcrumb,
        setIsError,
        handleElementClick,
        errorHandler,
        isCTAMobileLayer,
        setIsCTAMobileLayer,
        setCurrentSelectedStep,
        categoriesData,
        currentSelectedValue,
        setCurrentSelectedValue,
        setBreadcrumb,
        isCategoriesLoading,
      }}
    >
      {children}
    </HeroContext.Provider>
  );
}
